import axios from "axios";
//import BACKEND_URL from "../config";






const axiosInstance = axios.create({
	baseURL: 'https://euroavia.gr/api/',
    // baseURL: 'https://euroaviaathens.eu.pythonanywhere.com/api/',

})
export default axiosInstance;
