import React from 'react'
import './Skeleton.css'


export default function Skeleton() {
  return (
	<div class="skeleton-card">
		<div class="skeleton-card-img skeleton">
		</div>
    </div>
  )
}
