import React, { useContext, useEffect, useState } from 'react'
import './Sponsors.css'

import HaiImage from '../images/sponsors/HAI-colored.svg'
import AltairImage from '../images/sponsors/altair.svg'
// import WurthImage from '../images/sponsors/wurth.svg'
// import PitsirikosImage from '../images/sponsors/pitsirikos.svg'
import PyrforosImage from '../images/sponsors/pyrforos.svg'
import EfoodImage from '../images/sponsors/efood.svg'
import BestRcShopsImage from '../images/sponsors/best-rc-shops.svg'
import HellenicDronesImage from '../images/sponsors/hellenic-drones.svg'
import BbAccountingImage from '../images/sponsors/bbaccounting.svg'
import AvekImage from '../images/sponsors/avek.svg'
import GlobalImage from '../images/sponsors/global.png'
import LangContext from '../context/LangContext'
import FibermaxImage from '../images/sponsors/fibermax.svg'
import ElvalImage from '../images/sponsors/elval.svg'
// import MantopoulosImage from '../images/sponsors/mantopoulos.svg'
import EurobankImage from '../images/sponsors/eurobank.svg'
import KaoussisImage from '../images/sponsors/kaoussis.svg'
import NaxImage from '../images/sponsors/nax.png'
import AirportImage from '../images/sponsors/airport.svg'
import MotorOilImage from '../images/sponsors/motor-oil.svg'
import HellasDigitalImage from '../images/sponsors/hellasdigital.svg'
import axiosInstance from '../api/api'
import BACKEND_URL from '../config'
import Skeleton from './Skeleton'


export default function Sponsors2() {

    const [sponsors, setSponsors] = useState(null);
    const [loading, setLoading] = useState(true);

    async function fetchSponsors() {
        const response = await axiosInstance.get('/sponsors');
        setSponsors(response?.data?.data);
        setLoading(false);
    }

    useEffect(() => {
        fetchSponsors();
    }, [])

    const {lang} = useContext(LangContext);

    if (loading)
        return <Skeleton />

  return (
    <section className='section sponsors'>
        <h3 className='title'>{lang === 'en'? "Sponsors" : "Χορηγοί"}</h3>
        <div className="text">
            <p>{lang==='en'?"We deeply thank all of our sponsors for continuously supporting our team!" : "Ευχαριστούμε βαθύτατα όλους τους χορηγούς μας που μας στηρίζουν αδιάκοπα!"}</p>
            <div className='sponsors-wrapper'>

                {sponsors.map((sponsor) => {
                    return <div className='sponsor-card' style={{borderTop:'2px solid'+sponsor.hex_color}}>
                                <img src={BACKEND_URL+sponsor.thumbnail} loading='lazy' alt={sponsor.sponsor_name}/>
                            </div>
                })}
                {/* <div className='sponsor-card hai'>
                    <img src={HaiImage} loading='lazy' alt='Hellenic Aerospace Industry'/>
                </div>
                <div className='sponsor-card eurobank'>
                    <img src={EurobankImage} loading='lazy' alt='Eurobank Sponsor' />
                </div>
                <div className='sponsor-card elval'>
                    <img src={ElvalImage} loading='lazy' alt='Elval Sponsor'/>
                </div>
                <div className='sponsor-card airport'>
                    <img src={AirportImage} loading='lazy' alt='Athens International Airport Sponsor'/>
                </div>
                <div className='sponsor-card motor-oil'>
                    <img src={MotorOilImage} loading='lazy' alt='Motor Oil Sponsor'/>
                </div>
                <div className='sponsor-card avek'>
                    <img src={AvekImage} loading='lazy' alt='Avek Sponsor'/>
                </div>
                <div className='sponsor-card altair'>
                    <img src={AltairImage} loading='lazy' alt='Altair Sponsor' />
                </div>
                <div className='sponsor-card global'>
                        <img src={GlobalImage} loading='lazy' alt='Global Prep'/>
                </div>
                <div className='sponsor-card hellasdigital'>
                    <img src={HellasDigitalImage} loading='lazy' alt='HellasDigital Sponsor' />
                </div>

                <div className='sponsor-card pyrforos'>
                    <img src={PyrforosImage} loading='lazy' alt='Ntua Sponsor' />
                </div>

                <div className='sponsor-card efood'>
                    <img src={EfoodImage} loading='lazy' alt='Efood Sponsor'/>
                </div>
                
                <div className='sponsor-card bestrcshops'>
                    <img src={BestRcShopsImage} loading='lazy' alt='BestRcShops Sponsor'/>
                </div>

                <div className='sponsor-card hellenic-drones'>
                    <img src={HellenicDronesImage} loading='lazy' alt='Hellenic Drones Sponsor'/>
                </div>

                <div className='sponsor-card bb-accounting'>
                    <img src={BbAccountingImage} loading='lazy' alt='BB Accounting Sponsor'/>
                </div>
                <div className='sponsor-card fibermax'>
                    <img src={FibermaxImage} loading='lazy' alt='Fibermax Sponsor'/>
                </div>
                <div className='sponsor-card nax'>
                    <img src={NaxImage} loading='lazy' alt='Nax Sponsor'/>
                </div>
                <div className='sponsor-card kaoussis'>
                    <img src={KaoussisImage} loading='lazy' alt='Kaoussis Sponsor'/>
                </div> */}
                
            </div>
       
       </div>
    </section>
  )
}
